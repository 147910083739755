// COMPONENTS
import React from 'react'
import Page from 'templates/Page'
// CONTENT
import content from 'content/starthere.yml'

// Start Here page
const StartHerePage = () => {
  return <Page content={content} />
}

export default StartHerePage
